const daysSessions = [
	{
		"day": 0,
		"date": "2020-11-26",
		"sessions": [
			{
				"id": "1:1",
				"placeholder": false,
				"start": "09:00",
				"end": "10:30",
				"title": "Κλινικό Φροντιστήριο για Επαγγελματίες Υγείας και Ψυχοκοινωνικούς Επιστήμονες",
				"description": null
			},
			{
				"id": "1:2",
				"placeholder": false,
				"start": "10:30",
				"end": "11:30",
				"title": "Ελεύθερες Ανακοινώσεις",
				"description": null
			},
			{
				"id": "1:3",
				"placeholder": true,
				"start": "11:30",
				"end": "12:00",
				"title": "Διάλειμμα",
				"description": null
			},
			{
				"id": "1:4",
				"placeholder": false,
				"start": "12:00",
				"end": "13:00",
				"title": "Στρογγυλό Τραπέζι: HIV - Γυναίκα και παιδί",
				"description": null
			},
			{
				"id": "1:5",
				"placeholder": false,
				"start": "13:00",
				"end": "14:00",
				"title": "Στρογγυλό Τραπέζι: Η καθημερινότητα των ΜΕΛ στην εποχή του COVID-19: Παθήματα και μαθήματα",
				"description": null
			},
			{
				"id": "1:6",
				"placeholder": true,
				"start": "14:00",
				"end": "14:30",
				"title": "Διάλειμμα",
				"description": null
			},
			{
				"id": "1:7",
				"placeholder": false,
				"start": "14:30",
				"end": "16:30",
				"title": "Στρογγυλό Τραπέζι: Ευάλωτες ομάδες: μια συνεχιζόμενη πρόκληση",
				"description": null
			},
			{
				"id": "1:8",
				"placeholder": false,
				"start": "16:30",
				"end": "17:30",
				"title": "Στρογγυλό Τραπέζι: Μεγαλώνοντας με τον HIV",
				"description": null
			},
			{
				"id": "1:9",
				"placeholder": true,
				"start": "17:30",
				"end": "18:00",
				"title": "Διάλειμμα",
				"description": null
			},
			{
				"id": "1:10",
				"placeholder": false,
				"start": "18:00",
				"end": "18:30",
				"title": "Διάλεξη: Αύξηση σωματικού βάρους. Τι φταίει τελικά;",
				"description": null
			},
			{
				"id": "1:11",
				"placeholder": false,
				"start": "18:30",
				"end": "19:30",
				"title": "Στρογγυλό Τραπέζι: Η HIV λοίμωξη στην Ελλάδα του σήμερα",
				"description": null
			}
		],
		"parallelSessions": [
			{
				"id": "1:12",
				"placeholder": false,
				"start": "15:30",
				"end": "18:30",
				"title": "Κλινικό Φροντιστήριο Νοσηλευτών/Μαιών",
				"description": null
			}
		]
  },
  {
    "day": 1,
		"date": "2020-11-27",
		"sessions": [
			{
				"id": "2:1",
				"placeholder": false,
				"start": "09:00",
				"end": "10:30",
				"title": "Ελεύθερες Ανακοινώσεις",
				"description": null
			},
			{
				"id": "2:2",
				"placeholder": false,
				"start": "10:30",
				"end": "11:30",
				"title": "Στρογγυλό Τραπέζι: Τόσα χρόνια PREP. Μήπως PREP-ει τελικά;",
				"description": null
			},
			{
				"id": "2:3",
				"placeholder": true,
				"start": "11:30",
				"end": "12:00",
				"title": "Διάλειμμα",
				"description": null
			},
			{
				"id": "2:4",
				"placeholder": false,
				"start": "12:00",
				"end": "14:00",
				"title": "Στρογγυλό Τραπέζι: HIV και COVID-19: Δύο παράλληλες επιδημίες",
				"description": null
			},
			{
				"id": "2:5",
				"placeholder": false,
				"start": "14:00",
				"end": "14:30",
				"title": "Διάλεξη: Οι πολλαπλές όψεις του στίγματος σε επιδημίες",
				"description": null
			},
			{
				"id": "2:6",
				"placeholder": true,
				"start": "14:30",
				"end": "16:30",
				"title": "Διάλειμμα - Συνέλευση Ε.Ε.Μ.Α.Α.",
				"description": null
			},
			{
				"id": "2:7",
				"placeholder": false,
				"start": "16:30",
				"end": "17:30",
				"title": "Στρογγυλό Τραπέζι: Νεότερες θεραπευτικές πρακτικές",
				"description": null
			},
			{
				"id": "2:8",
				"placeholder": true,
				"start": "17:30",
				"end": "18:00",
				"title": "Διάλειμμα",
				"description": null
			},
			{
				"id": "2:9",
				"placeholder": false,
				"start": "18:00",
				"end": "19:30",
				"title": "Δορυφορικές Διαλέξεις",
				"description": null
			},
			{
				"id": "2:10",
				"placeholder": false,
				"start": "18:00",
				"end": "",
				"title": "Τελετή έναρξης",
				"description": null
			},
			
		],
		"parallelSessions": [
      {
				"id": "2:11",
				"placeholder": false,
				"start": "10:00",
				"end": "12:00",
				"title": "Εκπαίδευση Σωμάτων Ασφαλείας",
				"description": null
			},
			{
				"id": "2:12",
				"placeholder": false,
				"start": "13:00",
				"end": "15:00",
				"title": "Φροντιστήριο Προπτυχιακών Φοιτητών Επιστημών Υγείας",
				"description": null
      },
      {
				"id": "2:13",
				"placeholder": false,
				"start": "15:00",
				"end": "17:00",
				"title": "Εκπαίδευση Εκπαιδευτικών",
				"description": null
      },
      {
				"id": "2:14",
				"placeholder": false,
				"start": "13:00",
				"end": "15:00",
				"title": "Εκπαίδευση Ιδιωτών Επαγγελματιών Υγείας",
				"description": null
			},
		]
  },
  {
		"day": 2,
		"date": "2020-11-28",
		"sessions": [
			{
				"id": "3:1",
				"placeholder": false,
				"start": "09:00",
				"end": "10:30",
				"title": "Κλινικό Φροντιστήριο Οδοντιάτρων",
				"description": null
			},
			{
				"id": "3:2",
				"placeholder": false,
				"start": "10:30",
				"end": "12:00",
				"title": "Στρογγυλό Τραπέζι: Προβληματισμοί στην κλινική πράξη",
				"description": null
			},
			{
				"id": "3:3",
				"placeholder": true,
				"start": "12:00",
				"end": "12:30",
				"title": "Διάλειμμα",
				"description": null
			},
			{
				"id": "3:4",
				"placeholder": false,
				"start": "12:30",
				"end": "14:30",
				"title": "Στρογγυλό Τραπέζι: Η κοινωνία των πολιτών: Μαθήματα από την αντιμετώπιση του στίγματος",
				"description": null
      },
			{
				"id": "3:5",
				"placeholder": true,
				"start": "14:30",
				"end": "15:00",
				"title": "Διάλειμμα",
				"description": null
			},
			{
				"id": "3:6",
				"placeholder": false,
				"start": "15:00",
				"end": "17:00",
				"title": "Στρογγυλό Τραπέζι: Θεραπευτικές επιλογές. Κλινικά παραδείγματα (Διαδραστική συνεδρία)",
				"description": null
			},
			{
				"id": "3:7",
				"placeholder": false,
				"start": "17:00",
				"end": "18:00",
				"title": "Τι νεότερο το 2020",
				"description": null
			},
			{
				"id": "3:8",
				"placeholder": false,
				"start": "",
				"end": "",
				"title": "Συμπεράσματα - Λήξη Συνεδρίου",
				"description": null
			},
		],
		"parallelSessions": []
  },
]

export {daysSessions}