<template>
<div id="gdpr-container">
  <FormulateForm @submit="submitInParent" v-model="formValues" :keep-model-data="true" @input="emitToParent" ref="form_4">
    <p>Η {{ gdprVoids[0] }}, τηρώντας τη νέα ευρωπαϊκή νομοθεσία σχετικά με την προστασία των προσωπικών δεδομένων (GDPR),υποχρεούται να διατηρεί αρχείο με τους συμμετέχοντες σε επιστημονική συγκέντρωση ώστε να έχει τη δυνατότητα να ενημερώσει τις αρμόδιες αρχές (Ε.Ο.Φ. & Π.Ι.Σ.) για τους επιστήμονες υγείας που συμμετείχαν στην εκδήλωση.</p>
    <br>
    <p>Υπεύθυνος επεξεργασίας των στοιχείων είναι {{ gdprVoids[1] }}</p>
    <br>
    <p>Έχω ενημερωθεί για το δικαίωμα μου να απαιτήσω την διόρθωση των ανακριβών προσωπικών μου δεδομένων καθώς και να ασκήσω το δικαίωμα διαγραφής, περιορισμού της επεξεργασίας, φορητότητας των δεδομένων μου και εναντίωσης επεξεργασίας τους, εφόσον συντρέχουν οι προϋποθέσεις του νόμου, ενώ είμαι ενήμερος/-η ότι δικαιούμαι να ανακαλέσω οποτεδήποτε την παρούσα συγκατάθεσή μου, χωρίς να θιγεί η νομιμότητα της επεξεργασίας που προηγήθηκε της ανακλήσεως καθώς και ότι έχω δικαίωμα υποβολής καταγγελίας στην αρμόδια εποπτική αρχή εφόσον κρίνω ότι η επεξεργασία των προσωπικών δεδομένων μου αντίκειται στην ισχύουσα νομοθεσία.</p>
    <br>
    <dl>
      <dt>Για την εκδήλωση:</dt>
      <dd>
        <FormulateInput name="prv_gdpr_requirement_1" type="checkbox" :validation="['accepted']" label="Αποδέχομαι τη φωτογράφιση ή/και κινηματογράφηση της εκδήλωσης καθώς και τη δυνατότητα της εταιρείας να αναρτήσει αυτά στην ιστοσελίδα της." />
        <FormulateInput name="prv_gdpr_requirement_2" type="checkbox" :validation="['accepted']" label="Αποδέχομαι τη χρήση και διατήρηση των στοιχείων μου από την Εταιρεία σε κατάσταση με τους συμμετέχοντες στην επιστημονική εκδήλωση, ώστε να έχει τη δυνατότητα να τα θέσει υπόψη των αρμοδίων αρχών (ΕΟΦ και ΠΙΣ) εφόσον της ζητηθεί." />
      </dd>
    </dl>
    <br>
    <br>
    <dl>
      <dt>Αποδέχομαι να χρησιμοποιηθούν τα στοιχεία επικοινωνίας που έχω αναγράψει, από την Εταιρεία με αποκλειστικό σκοπό την ενημέρωσή μου σχετικά με τις επιστημονικές δραστηριότητές της.</dt>
      <dd>
        <FormulateInput name="prv_gdpr_choice_1" type="radio" :options="{yes: 'ΝΑΙ', no: 'ΟΧΙ'}" />
      </dd>
    </dl>
    <br>
    <dl>
      <dt>Συγκατατίθεμαι πλήρως, ρητά και ανεπιφύλακτα στη συλλογή, καταγραφή, επεξεργασία, χρήση και εν γένει τήρηση από την Εταιρεία αρχείου σε ηλεκτρονική ή/και έντυπη μορφή με τα προσωπικά δεδομένα μου και στη χρήση αυτού του αρχείου σύμφωνα με την ισχύουσα ελληνική και ευρωπαϊκή νομοθεσία.</dt>
      <dd>
        <FormulateInput name="prv_gdpr_choice_2" type="radio" :options="{yes: 'ΝΑΙ', no: 'ΟΧΙ'}" />
      </dd>
    </dl>
    <br>
    <dl>
      <dt>Επιτρέπω στην Εταιρεία ή σε οποιουσδήποτε τρίτους με τους οποίους συνεργάζεται επί συμβάσει και οι οποίοι ενεργούν για λογαριασμό της, να χρησιμοποιούν τα στοιχεία επικοινωνίας μου ώστε να μπορεί η Εταιρεία να μου αποστέλλει υλικό μέσω email, SMS/μηνύματος κειμένου σχετικά με τις υπηρεσίες της.</dt>
      <dd>
        <FormulateInput name="prv_gdpr_choice_3" type="radio" :options="{yes: 'ΝΑΙ', no: 'ΟΧΙ'}" />
      </dd>
    </dl>
    <br>
    <p>Συμφωνώ και αποδέχομαι όλα τα ανωτέρω</p>
    <FormulateInput type="submit" name="submit_5_button" id="submit_5_button" label="Αποδοχή και Υποβολή" :disabled="buttonDisability" class="step-submit-btn" />
  </FormulateForm>

</div>
</template>

<script>
export default {
  name: "GDPRContainer",
  props: [
    "gdprVoids"
  ],
  data() {
    return {
      formValues: {},
      validation: {},
      valid: false
    }
  },
  computed: {
    buttonDisability: function () {

      return !this.valid
    }
  },
  methods: {
    async emitToParent() {
      this.$emit("childToParentGDPR", this.formValues)
      this.valid = !await this.$refs.form_4.hasValidationErrors()
      this.$emit("childToParentValidateGDPR", 3, this.valid)
    },
    submitInParent() {
      this.$emit("childToParentSubmit")
    }
  }
}
</script>

<style scoped>
#gdpr-container {
  text-align: left;
}

.input-radio {
  margin-left: 20px;
  margin-top: 20px;
}

.input-checkbox {
  margin-top: 20px;
}
</style>
