<template>
<div id="subscription-container">

  <FormulateForm id="form_3" ref="form_3" v-model="formValues" @submit="submitInParent" :keep-model-data="true" @input="emitToParent" @validation="handleValidation()">
    <h4>{{ chargeStep.subscription_header }}</h4>
    <div>
      <FormulateInput validation="required" type="radio" name="trn_subscription_plan" id="trn_subscription_plan" :options="subscriptions" />
    </div>
    <h4 v-if="!isStudent"> {{ chargeStep.payment_header }}</h4>
    <div>
      <FormulateInput v-if="!isStudent" validation="required" type="radio" name="trn_payment_method" id="payment_method" :options="payment_methods" />
    </div>
    <p v-if="!isStudent">{{ chargeStep.step_footer }}</p>
    <FormulateInput type="submit" name="submit_3_button" id="submit_3_button" label="Επόμενο" :disabled="buttonDisability" class="step-submit-btn" />
  </FormulateForm>

</div>
</template>

<script>
import * as jsonLogic from "../../node_modules/json-logic-js/logic"

export default {
  name: "SubscriptionContainer",
  props: ["chargeStep", "values", "expressions", ],
  data() {
    return {
      formValues: {},
      validity: false,
    };
  },
  computed: {
    isStudent: function () {
      return jsonLogic.apply(this.expressions.eligibility.student, this.values)
    },
    buttonDisability: function () {
      // var allValues = true
      // Object.keys(this.formValues).forEach(key => {
      //   console.log(key, ": ", this.formValues[key])
      //   if (this.formValues[key] == "") {
      //     allValues = false
      //   }
      // })
      console.log("button disabled: ", !this.validity)
      return !this.validity
    },
    subscriptions: function () {
      return this.getEligibleChoices(this.chargeStep.subscriptions)
    },
    payment_methods: function () {
      return this.getEligibleChoices(this.chargeStep.payment_methods)
    },
    // Find the selected price
    selectedPackage: function () {
      var selPackage = {}
      for (const sub of this.chargeStep.subscriptions) {
        if (sub.code == this.formValues["subscription_plan"]) {
          selPackage = sub
          break
        }
      }
      return selPackage
    }
  },

  methods: {
    getEligibleChoices(array) {
      var items = {}

      // var pr = payment ? parseInt(this.selectedPackage.price) : 0

      array.forEach(item => {
        const item_elig = item.eligibility
        const elig_expr = this.expressions.eligibility[item_elig]

        if (jsonLogic.apply(elig_expr, this.values)) {
          items[item.code] = item.title + ": " + parseFloat(item.price) + " " + item.currency + item.asterisk + ". " + item.description
          // items[item.code] = item.title + ": " + (parseFloat(item.price) + pr) + " " + item.currency + item.asterisk + ". " + item.description
        }
      })
      return items
    },
    emitToParent() {
      this.$emit("childToParentSubscription", this.formValues)
    },
    async handleValidation() {
      this.validity = !await this.$refs.form_3.hasValidationErrors()
      this.$emit("childToParentValidateSubscription", 2, this.validity)
    },
    submitInParent() {
      this.$emit("childToParentToggleStep", 3)
    }
  },
}
</script>

<style scoped>
#subscription_container {
  width: 100%;
}

#form_3 {
  text-align: left;
}
</style>
