const professionSelection = {
  profession_doctor_choice: "Ιατρός",
  profession_dentist_choice: "Οδοντίατρος",
  profession_vet_choice: "Κτηνίατρος",
  profession_pharmacist_choice: "Φαρμακοποιός",
  profession_biologist_choice: "Βιολόγος",
  profession_biochemist_choice: "Βιοχημικός",
  profession_other_health_profession_choice: "Άλλος Επαγγελματίας Υγείας",
  profession_college_student_choice: "Φοιτητής",
  profession_student_choice: "Σπουδαστής",
  profession_other_choice: "prf_conditional_profession_other_input",
};

const expressions = {
  visibility: {
    contact_address_is_workplace: {
      "==": [
        {
          var: "prf_contact_address_select",
        },
        "contact_address_workplace_choice",
      ],
    },
    profession_is_other: {
      "==": [{ var: "prf_profession_select" }, "profession_other_choice"],
    },
    other_health_profession_is_other: {
      "==": [
        { var: "prf_other_health_profession_select" },
        "other_health_profession_choice17",
      ],
    },
    college_student_is_other: {
      "==": [{ var: "prf_college_student_select" }, "college_student_choice22"],
    },
    profession_choice_is_doctor: {
      "==": [{ var: "prf_profession_select" }, "profession_doctor_choice"],
    },
    profession_choice_is_college_student: {
      "==": [
        { var: "prf_profession_select" },
        "profession_college_student_choice",
      ],
    },
    profession_choice_is_student: {
      "==": [{ var: "prf_profession_select" }, "profession_student_choice"],
    },
    profession_choice_is_other_health_profession: {
      "==": [
        { var: "prf_profession_select" },
        "profession_other_health_profession_choice",
      ],
    },
    specialty_choice_is_specialized: {
      "==": [{ var: "prf_specialty_select" }, "doctor_specialized"],
    },
    doctor_is_ultra_specialized: {
      "==": [{ var: "prf_ultra_specialty_radio" }, "doctor_ultra_specialized"],
    },
    doctor_is_trainee_or_specialized: {
      or: [
        { "==": [{ var: "prf_specialty_select" }, "doctor_specialized"] },
        { "==": [{ var: "prfspecialty_select" }, "doctor_trainee"] },
      ],
    },
    profession_choice_is_doctor_and_specialized: {
      and: [
        {
          "==": [{ var: "prf_profession_select" }, "profession_doctor_choice"],
        },
        { "==": [{ var: "prf_specialty_select" }, "doctor_specialized"] },
      ],
    },
    invoice_choice_is_yes: {
      "==": [{ var: "prf_invoice_radio" }, "invoice_yes_choice"],
    },
  },
  autocomplete: {
    invoice_brandname_checkbox_is_same: {
      expression: { "==": [{ var: "prf_invoice_brandname_checkbox" }, true] },
      autocomplete_value: "prf_lastname",
      selection: {},
    },
    invoice_profession_checkbox_is_same: {
      expression: { "==": [{ var: "prf_invoice_profession_checkbox" }, true] },
      autocomplete_value: "prf_profession_select",
      selection: professionSelection,
    },
    invoice_address_street_checkbox_is_same: {
      expression: {
        "==": [{ var: "prf_invoice_address_street_checkbox" }, true],
      },
      autocomplete_value: "prf_address_street",
      selection: {},
    },
    invoice_address_number_checkbox_is_same: {
      expression: {
        "==": [{ var: "prf_invoice_address_number_checkbox" }, true],
      },
      autocomplete_value: "prf_address_number",
      selection: {},
    },
    invoice_address_city_checkbox_is_same: {
      expression: {
        "==": [{ var: "prf_invoice_address_city_checkbox" }, true],
      },
      autocomplete_value: "prf_address_city",
      selection: {},
    },
    invoice_postal_code_checkbox_is_same: {
      expression: { "==": [{ var: "prf_invoice_postal_code_checkbox" }, true] },
      autocomplete_value: "prf_postal_code",
      selection: {},
    },
    invoice_country_checkbox_is_same: {
      expression: { "==": [{ var: "prf_invoice_country_checkbox" }, true] },
      autocomplete_value: "prf_address_country",
      selection: {},
    },
  },
  validate_passwords: {
    registration_passwords: {
      "==": [
        { var: "registration_password" },
        { var: "registration_password_repeat" },
      ],
    },
  },
  eligibility: {
    "*": {
      "==": [1, 1],
    },
    student: {
      or: [
        {
          "==": [{ var: "prf_profession_select" }, "profession_student_choice"],
        },
        {
          "==": [
            { var: "prf_profession_select" },
            "profession_college_student_choice",
          ],
        },
      ],
    },
    not_student: {
      "!": [
        {
          or: [
            {
              "==": [
                { var: "prf_profession_select" },
                "profession_student_choice",
              ],
            },
            {
              "==": [
                { var: "prf_profession_select" },
                "profession_college_student_choice",
              ],
            },
          ],
        },
      ],
    },
  },
};

export { expressions };
