<template>
<div id="container">

  <div id="forms-container" v-if="!submited">
    <div name="step_1" id="step_1" class="step-container">
      <div name="step_1_title" id="step_1_title" class="step-title-div open" @click="toggleStep(0)">
        <h3 class="step-title">
          <span><i id="step_1_arrow" class="arrow down"></i></span>
          Εγγραφή
        </h3>
      </div>
      <div name="step_1_content" id="step_1_content" class="step-content flex-container flex-vertical  open">
        <div class="step-description"></div>
        <div name=" step_1_form" id="step_1_form" class="step-form-container">
          <div name="step_1_form_registration" id="step_1_form_registration">
            <FormulateForm @submit="request(1)" v-if="currentStep === 'step_1'" v-model="formValues" :keep-model-data="true" :schema="targetSchema" @validation="handleValidation(0)" ref="form_0">
              <FormulateInput type="submit" name="step_1_submit" id="step_1_submit" :disabled="buttons[0]['disabled']" label="Επόμενο" class="step-submit-btn" />
            </FormulateForm>
          </div>
        </div>
      </div>

    </div>

    <div name="step_2" id="step_2" class="step-container">
      <div name="step_2_title" id="step_2_title" class="step-title-div" @click="toggleStep(1)">
        <h3 class="step-title">
          <span><i id="step_2_arrow" class="step_2_arrow arrow right"></i></span>
          Προσωπικά Στοιχεία
        </h3>
      </div>
      <div name="step_2_content" id="step_2_content" class="step-content flex-container flex-vertical">
        <div class="step-description"></div>
        <div name="step_2_form" id="step_2_form" class="step-form-container">
          <FormulateForm @submit="request(2)" v-if="currentStep === 'step_2'" v-model="formValues" :keep-model-data="true" :schema="targetSchema" @validation="handleValidation(1)" ref="form_1">
            <FormulateInput type="submit" name="step_2_submit" id="step_2_submit" :disabled="buttons[1]['disabled']" label="Επόμενο" class="step-submit-btn" />
          </FormulateForm>
        </div>
      </div>
    </div>

    <div name="step_3" id="step_3" class="step-container">
      <div name="step_3_title" id="step_3_title" class="step-title-div" @click="toggleStep(2)">
        <h3 class="step-title">
          <span><i id="step_3_arrow" class="step_3_arrow arrow right"></i></span>
          Επιλογή Χρέωσης
        </h3>
      </div>
      <div name="step_3_content" id="step_3_content" class="step-content flex-container flex-vertical">
        <div class="step-description"></div>
        <div name="step_3_form" id="step_3_form" class="step-form-container">
          <!--<subscription-container :chargeStep="computedChargeStep" :values="formValues" :expressions="expressions" @childToParentSubscription="insertChildFormValues" @childToParentToggleStep="request" @childToParentValidateSubscription="handleValidationFromChild"></subscription-container> -->
          <subscription-container v-if="currentStep === 'step_3'" :chargeStep="computedChargeStep" :values="formValues" :expressions="expressions" :valid="steps[2]['valid']" @childToParentSubscription="insertChildFormValues" @childToParentToggleStep="request" @childToParentValidateSubscription="handleValidationFromChild"></subscription-container>

        </div>
      </div>
    </div>
    <!--
    <div name="step_4" id="step_4" class="step-container">
      <div name="step_4_title" id="step_4_title" class="step-title-div" @click="toggleStep(3)">
        <h3 class="step-title">
          Παρακολούθηση Συνεδριών
        </h3>
      </div>
      <div name="step_4_content" id="step_4_content" class="step-content flex-container flex-vertical">
        <div class="step-description"></div>
        <div name="step_4_form" id="step_4_form" class="step-form-container">
          <toggle-sessions :daysSessions="daysSessions" @childToParentFormValues="insertChildFormValues" @childToParentToggleStep="request" @childToParentValidate="handleValidationFromChild"> </toggle-sessions>
        </div>
      </div>
    </div>
    -->

    <div name="step_4" id="step_4" class="step-container">
      <div name="step_4_title" id="step_4_title" class="step-title-div" @click="toggleStep(3)">
        <h3 class="step-title">
          <span><i id="step_4_arrow" class="step_4_arrow arrow right"></i></span>
          Αποδοχή Όρων
        </h3>
      </div>
      <div name="step_4_content" id="step_4_content" class="step-content flex-container flex-vertical">
        <div class="step-description"></div>
        <div name="step_4_form" id="step_4_form" class="step-form-container">
          <gdpr-container :gdprVoids="gdprVoids" :formValues="formValues" @childToParentGDPR="insertChildFormValues" @childToParentSubmit="request" @childToParentValidateGDPR="handleValidationFromChild"></gdpr-container>
        </div>
      </div>
    </div>
    <p id="http-error-response"></p>

  </div>

  <div v-if="submited">
    <div class="flex-container flex-row-center--h flex-row-center--v">
      <h2>Η εγγραφή υποβλήθηκε επιτυχώς.</h2>
    </div>
    <div v-if="debug">
      <h1>Results</h1>
      <p v-for="key in Object.keys(formValues)" :key="key"> {{key}}: {{formValues[key]}}</p>
    </div>
  </div>

  <div id="debug" v-if="debug && !submited">
    <hr>
    <h1> formValues:</h1>
    <h4> {{ formValues}} </h4>
    <hr>
  </div>
</div>
</template>

<script>
import * as dfs_function from "../assets/js/dfsComponents";
import * as jsonLogic from "../../node_modules/json-logic-js/logic"
import axios from "axios"
// import ToggleSessions from "../components/ToggleSessions"
import GDPRContainer from "./GDPRContainer"
import SubscriptionContainer from "./SubscriptionContainer";

import VueSimpleAlert from "vue-simple-alert";
import Vue from "vue";
Vue.use(VueSimpleAlert)

export default {

  name: "FormContainer",
  props: ["targetUrl",
    "schema", "expressions", "daysSessions", "chargeStep", "gdprVoids", "debug"
  ],
  data() {
    return {
      formValues: {},
      currentStep: "step_1",
      submited: false,
      student: false,
      steps: [{
          name: "step_1",
          valid: this.debug,
          open: true,
          validation: {}
        },
        {
          name: "step_2",
          valid: this.debug,
          open: false,
          validation: {}
        },
        {
          name: "step_3",
          valid: this.debug,
          open: false,
          validation: {}
        },
        {
          name: "step_4",
          valid: this.debug,
          open: false,
          validation: {}
        },
        // {
        //   name: "step_5",
        //   valid: false,
        //   open: false,
        //   validation: {}
        // },
      ]

    };

  },
  computed: {
    buttons: function () {

      var btns = []
      this.steps.forEach(step => {
        var disabledBtn = this.debug ? false : !step.valid

        var btn = {
          "step": step.name,
          "disabled": disabledBtn
        }
        btns.push(btn)
      })
      return btns
    },
    computedChargeStep: function () {

      // Filter out subscriptions that the user cannot have
      // If user is student, has a free plan, thus, no payment (delete it)
      var computedChargeStep = JSON.parse(JSON.stringify(this.chargeStep))

      return this.computeChargeStep(computedChargeStep, this.formValues)
      // computedChargeStep.subscriptions.filter(sub => {
      //   const sub_elig = sub.eligibility
      //   const elig_expr = this.expressions.eligibility[sub_elig]

      //   if (jsonLogic.apply(elig_expr, this.formValues)) {
      //     if (sub.eligibility == "student") {
      //       payment = false
      //     }
      //     return sub
      //   }
      // })
      // if (!payment) {
      //   delete computedChargeStep.payment_methods
      // }

      // return computedChargeStep

    },
    targetSchema: function () {

      const stepIndex = parseInt(this.currentStep.split("_")[1]) - 1

      var inputSchema = JSON.parse(JSON.stringify(this.schema[stepIndex]))

      if (stepIndex === 0) {
        inputSchema = JSON.parse(JSON.stringify(this.schema[stepIndex][0]["children"][1]))
      }

      const resultSchema = dfs_function.dfs(inputSchema, this.expressions, this.formValues)

      return resultSchema

    },

  },
  methods: {
    computeChargeStep(computedChargeStep, values) {
      var payment = true

      computedChargeStep.subscriptions.filter(sub => {
        const sub_elig = sub.eligibility
        const elig_expr = this.expressions.eligibility[sub_elig]

        if (jsonLogic.apply(elig_expr, values)) {
          if (sub.eligibility == "student") {
            payment = false
            this.student = true
          }
          return sub
        }
      })
      if (!payment) {
        delete computedChargeStep.payment_methods
      } else {
        this.student = false
      }

      return computedChargeStep
    },
    request(nextStep = "SUBMIT_FORM") {
      nextStep == "SUBMIT_FORM" ? this.requestPromise(nextStep).then(this.handleSubmit) : this.requestPromise(nextStep).then(step => this.toggleStep(step))
    },
    requestPromise(nextStep) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {

          // TO-DO
          const error = false;

          error ? reject("Error: Something went wrong") : resolve(nextStep)
        }, 300)
      })
    },
    handleSubmit() {
      axios.post(this.targetUrl, {
          "emailaddress": this.formValues["registration_email"],
          "password": this.formValues["registration_password"],
          "profiledata": this.formValues
        })
        .then(response => {
          console.log("response:", response)
          // this.submited = true
          if (response.data.error != "") {
            alert(response.data.error)
            return
          }

          if (response.data.userRegistered && response.data.eventRegistered) {
            console.log("OK")
            this.submited = true
          } else {

            const popupInfo = {
              icon: "error",
              title: "<h5 style='width: 90%; margin: 0 auto;'>Ο χρήστης με email '" + this.formValues.registration_email + "' υπάρχει ήδη.</h5></br></br>",
              text: "Προσπαθήστε ξανά με διαφορετικό emal.",
              showConfirmButton: true,
              confirmButtonText: "OK",
              confirmButtonColor: "#a62525"
            }
            this.$fire(popupInfo)
            console.log("User already exists")

          }
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async handleValidation(stepNumber) {
      var step = this.steps[stepNumber]

      this.debug ? step.valid = true : step.valid = !await this.$refs["form_" + stepNumber].hasValidationErrors()

    },
    handleValidationFromChild(stepNumber, valid) {
      this.steps[stepNumber].valid = this.debug ? true : valid
    },
    toggleStep(stepNumber) {

      // If previous steps are not valid, do not open the clicked step
      for (var i = 0; i < stepNumber; i++) {
        if (!this.steps[i]["valid"]) {
          return
        }
      }

      // If already opened, cannot close
      if (this.steps[stepNumber].open) return

      // In case the step toggled is before an already processed step
      // invalidate all the steps forward so that the user has to go through
      // them again. 
      for (i = stepNumber + 1; i < this.steps.length; i++) {
        this.steps[i].valid = false
      }

      var stepName = "step_" + (stepNumber + 1)

      this.steps = this.steps.map((step) => {

        if (step.name != stepName) {

          step.open = false;

          if (document.getElementById(step.name + "_content")) {
            document.getElementById(step.name + "_arrow").classList.remove("down")
            document.getElementById(step.name + "_arrow").classList.add("right")
            document.getElementById(step.name + "_title").classList.remove("open")
            document.getElementById(step.name + "_content").classList.remove("open")
          }

        } else {

          if (step.open) {
            if (document.getElementById(step.name + "_content")) {
              document.getElementById(step.name + "_arrow").classList.remove("down")
              document.getElementById(step.name + "_arrow").classList.add("right")
              document.getElementById(step.name + "_content").classList.remove("open")
              document.getElementById(step.name + "_title").classList.remove("open")
            }
          } else {
            if (document.getElementById(step.name + "_content")) {
              document.getElementById(step.name + "_arrow").classList.remove("right")
              document.getElementById(step.name + "_arrow").classList.add("down")
              document.getElementById(step.name + "_title").classList.add("open")
              document.getElementById(step.name + "_content").classList.add("open")
            }
          }

          this.currentStep = stepName
          step.open = !step.open
        }

        return step
      })

    },
    insertChildFormValues(childValues) {
      for (const [key, value] of Object.entries(childValues)) {
        this.formValues[key] = value
      }
    },
  },
  components: {
    // "toggle-sessions": ToggleSessions,
    "subscription-container": SubscriptionContainer,
    "gdpr-container": GDPRContainer,
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
#container {
  width: 100%;
}

#forms-container {
  max-width: 800px;
  margin: 0 auto;
}

.step-container {
  margin: 30px auto;
  box-shadow: 2px 5px 10px grey
}

.step-title-div {
  filter: brightness(90%);
}

.step-title {
  text-align: left;
  padding-left: 20px;
}

.step-title-div:hover {
  cursor: pointer;
  text-decoration: underline;
}

.step-title-div.open {
  filter: brightness(130%);
}

.step-content {
  opacity: 0;
  max-height: 0px;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}

.step-content.open {
  opacity: 1;
  max-height: 10000px;
  padding: 30px;
}

.step-form-container {
  width: 100%;
}

.step-description {}

button[type="submit"] {
  box-shadow: 2px 5px 5px grey
}

.step-submit-btn {
  padding-top: 30px;
  padding-left: 10px;
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  margin-right: 10px;
}

.arrow.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transition: all 0.4s ease-out
}

.arrow.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transition: all 0.4s ease-out
}

.arrow.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transition: all 0.4s ease-out
}

.arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: all 0.4s ease-out
}
</style>
