<template>
<div id="app">

  <form-container :targetUrl="targetUrl" :schema="schema" :expressions="expressions" :daysSessions="daysSessions" :chargeStep="chargeStep" :gdprVoids="gdpr_voids" :debug="debug">
  </form-container>
</div>
</template>

<script>
import FormContainer from "./components/FormContainer";

import {
  daysSessions
} from "./assets/js/aids-schedule";
import {
  schema,
  chargeStep
} from "./assets/js/schema4";
import {
  expressions
} from "./assets/js/expressions";

export default {
  name: "App",
  data() {
    return {
      expressions: expressions,
      schema: schema,
      daysSessions: daysSessions,
      chargeStep: chargeStep,
      debug: false,
      targetUrl: "https://cygnus-dist.infinity.gr/api/events/aids/registeruser",
      gdpr_voids: ["Ε.Ε.Μ.Α.Α.", "η Ελληνική Εταιρεία Μελέτης και Αντιμετώπισης του AIDS με έδρα την Αθήνα, Ερυθρού Σταυρού 6, Τ.Κ. 11526, Τηλέφωνο: 210 6983945 , Φαξ: 210 6983945, e-mail: eemaa@aids.org.gr. Υπεύθυνος Επικοινωνίας: κ. Μαρία Μίντζια - Φωκά."]
    }
  },
  components: {
    "form-container": FormContainer,
    // "example": Example
  }

};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* Used for login & registration side by side */
/* 
.inline-div {
  opacity: 0.6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.inline-div.selected {
  opacity: 1;
}

*/

.flex-container {
  display: flex;
}

.flex-row-center--h {
  justify-content: center;
}

.flex-row-center--v {
  align-items: center;
}

.flex-horizontal {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-vertical {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.row {
  margin-top: 1em;
}

.logical-group {
  margin-top: 50px;
}

/* -------------------- */

.input-outer {
  text-align: left;
  padding: 10px;
}

.input-wrapper {
  text-align: left;
}

.input-hasError-label {}

.input-error-message {}

.required-input-label:before {
  content: "*";
}

.input-help {}

/* ---------- ----------*/

/* .flex-multiple-items .formulate-input {
  margin-right: 2em;
} */

input[type="text"],
input[type="password"],
input[type="email"] {
  width: 200px;
}

.radio-container div .formulate-input-element--group {
  /*input[data-type="radio"] .formulate-input-wrapper .formulate-input-element--group {*/
  text-align: left;
  margin-left: 30px;
}

.formulate-input-element--radio {
  display: inline-block;
}

/*.checkbox-container div div div div div { */
.formulate-input-element--checkbox {
  display: inline-block;
}

.checkbox-container {
  align-self: center;
}

.formulate-input .formulate-input-wrapper {
  text-align: left;
}

.formulate-input-errors {
  list-style-type: none;
  padding-left: 0;
  font-style: italic;
  font-size: x-small;
  text-align: left;
}
</style>
