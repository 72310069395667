import Vue from "vue";
import App from "./App.vue";
import VueFormulate from "@braid/vue-formulate";

Vue.use(VueFormulate, {
  locales: {
    en: {
      required() {
        return `* Το πεδίο είναι υποχρεωτικό`;
      },
      contactPhonesRule(args) {
        var numberOfFields = args["args"][0];
        return `* Συμπληρώστε υποχρεωτικά ${numberOfFields} από τα πεδία.`;
      },
      matchingPasswordsRule() {
        return `* Αποτυχία επιβεβαίωσης κωδικού`;
      },
      number() {
        return "* Το πεδίο πρέπει να είναι αριθμός";
      },
    },
  },
  rules: {
    contactPhonesRule: () => {
      return false;
    },
    matchingPasswordsRule: () => {
      return false;
    },
  },
});
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
