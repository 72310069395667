const schema = [
  [
    {
      component: "div",
      name: "step_1_content",
      id: "step_1_content",
      class: [],
      children: [
        [
          {
            component: "div",
            name: "step_1_login_content",
            id: "step_1_login_content",
            children: [
              {
                component: "div",
                name: "login_email_row",
                id: "login_email_row",
                class: ["flex-container", "flex-horizontal"],
                children: [
                  {
                    type: "email",
                    name: "login_email",
                    id: "login_email",
                    label: "Email",
                    "label-class": "required-input-label",
                    "label-hasErrors-class": "input-hasError-label",
                    "outer-class": "input-outer",
                    "wrapper-class": "input-wrapper",
                    "error-class": "input-error-message",
                    "help-class": "input-help",
                    validation: ["^required"],
                  },
                ],
              },
              {
                component: "div",
                name: "login_password_row",
                id: "login_password_row",
                class: ["flex-container", "flex-horizontal"],
                children: [
                  {
                    type: "password",
                    name: "login_password",
                    id: "login_password",
                    label: "Κωδικός",
                    "label-class": "required-input-label",
                    "label-hasErrors-class": "input-hasError-label",
                    "outer-class": "input-outer",
                    "wrapper-class": "input-wrapper",
                    "error-class": "input-error-message",
                    "help-class": "input-help",
                    validation: ["^required"],
                  },
                ],
              },
            ],
          },
        ],
        [
          {
            component: "div",
            name: "step_1_registration_content",
            id: "step_1_registration_content",
            children: [
              {
                component: "div",
                name: "registration_email_row",
                id: "registration_email_row",
                class: ["flex-container", "flex-horizontal"],
                children: [
                  {
                    type: "email",
                    name: "registration_email",
                    id: "registration_email",
                    label: "Email",
                    "label-class": "required-input-label",
                    "label-hasErrors-class": "input-hasError-label",
                    "outer-class": "input-outer",
                    "wrapper-class": "input-wrapper",
                    "error-class": "input-error-message",
                    "help-class": "input-help",
                    validation: ["^required"],
                  },
                ],
              },
              {
                component: "div",
                name: "registration_passwords_row",
                id: "registration_passwords_row",
                class: [
                  "flex-container",
                  "flex-horizontal",
                  "flex-multiple-items",
                ],
                children: [
                  {
                    type: "password",
                    name: "registration_password",
                    id: "registration_password",
                    label: "Κωδικός",
                    "label-class": "required-input-label",
                    "label-hasErrors-class": "input-hasError-label",
                    "outer-class": "input-outer",
                    "wrapper-class": "input-wrapper",
                    "error-class": "input-error-message",
                    "help-class": "input-help",
                    validation: ["^required", "min:8,length"],
                    class: [],
                  },
                  {
                    type: "password",
                    name: "registration_password_repeat",
                    id: "registration_password_repeat",
                    label: "Επαλήθευση κωδικού",
                    validate_passwords: "registration_passwords",
                    "label-class": "required-input-label",
                    "label-hasErrors-class": "input-hasError-label",
                    "outer-class": "input-outer",
                    "wrapper-class": "input-wrapper",
                    "error-class": "input-error-message",
                    "help-class": "input-help",
                    validation: ["^required"],
                    "error-behavior": "blur",
                    class: [],
                  },
                ],
              },
            ],
          },
        ],
      ],
    },
  ],
  [
    {
      component: "div",
      name: "step_2_content",
      id: "step_2_content",
      class: [],
      children: [
        {
          component: "div",
          name: "names_row",
          id: "names_row",
          class: [
            "flex-container",
            "flex-horizontal",
            "flex-multiple-items",
            "logical-group",
          ],
          children: [
            {
              type: "text",
              name: "prf_firstname",
              id: "prf_firstname",
              label: "Όνομα",
              "label-class": "required-input-label",
              "label-hasErrors-class": "input-hasError-label",
              "outer-class": "input-outer",
              "wrapper-class": "input-wrapper",
              "error-class": "input-error-message",
              "help-class": "input-help",
              validation: ["^required"],
              class: [],
            },
            {
              type: "text",
              name: "prf_lastname",
              id: "prf_lastname",
              label: "Επώνυμο",
              "label-class": "required-input-label",
              "label-hasErrors-class": "input-hasError-label",
              "outer-class": "input-outer",
              "wrapper-class": "input-wrapper",
              "error-class": "input-error-message",
              "help-class": "input-help",
              validation: ["^required"],
              class: [],
            },
            {
              type: "text",
              name: "prf_fathersname",
              id: "prf_fathersname",
              label: "Πατρώνυμο",
              "label-class": "required-input-label",
              "label-hasErrors-class": "input-hasError-label",
              "outer-class": "input-outer",
              "wrapper-class": "input-wrapper",
              "error-class": "input-error-message",
              "help-class": "input-help",
              validation: ["^required"],
              class: [],
            },
          ],
        },
        {
          component: "div",
          name: "contact_phone_container",
          id: "contact_phone_container",
          class: ["logical-group"],
          children: [
            {
              type: "group",
              name: "prf_contact_phones_group",
              id: "prf_contact_phones_group",
              label: "Τηλέφωνο επικοινωνίας",
              "label-class": "required-input-label",
              "label-hasErrors-class": "input-hasError-label",
              "outer-class": "input-outer",
              "wrapper-class": "input-wrapper",
              "error-class": "input-error-message",
              "help-class": "input-help",
              validation_class: ["minimum-fields-requirement"],
              validation_args: { "minimum-fields-requirement": "1" },
              validation: [],
              "error-behavior": "blur",
              help: "Εισάγετε τουλάχιστον 1",
              children: [
                {
                  component: "div",
                  name: "contact_phones_row",
                  id: "contact_phones_row",
                  class: [
                    "flex-container",
                    "flex-horizontal",
                    "flex-multiple-items",
                  ],
                  children: [
                    {
                      type: "text",
                      name: "prf_cellphone_number",
                      id: "prf_cellphone_number",
                      label: "Κινητό",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["number"],
                      "error-behavior": "live",
                    },
                    {
                      type: "text",
                      name: "prf_work_phonenumber",
                      id: "prf_work_phonenumber",
                      label: "Εργασίας",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["number"],
                      "error-behavior": "live",
                    },
                    {
                      type: "text",
                      name: "prf_home_phonenumber",
                      id: "prf_home_phonenumber",
                      label: "Οικίας",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["number"],
                      "error-behavior": "live",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          component: "div",
          name: "contact_address_container",
          id: "contact_address_container",
          class: ["logical-group"],
          children: [
            {
              component: "div",
              name: "contact_address_radio_row",
              id: "contact_address_radio_row",
              class: ["flex-container", "flex-horizontal"],
              children: [
                {
                  type: "radio",
                  name: "prf_contact_address_select",
                  id: "prf_contact_address_select",
                  label: "Διεύθυνση επικοινωνίας",
                  "label-class": "required-input-label",
                  "label-hasErrors-class": "input-hasError-label",
                  "outer-class": "input-outer",
                  "wrapper-class": "input-wrapper",
                  "error-class": "input-error-message",
                  "help-class": "input-help",
                  class: ["radio-container"],
                  options: {
                    contact_address_workplace_choice: "Εργασίας",
                    contact_address_home_choice: "Οικίας",
                  },
                  validation: ["^required"],
                },
              ],
            },

            {
              component: "div",
              name: "address_container",
              id: "address_container",
              children: [
                {
                  component: "div",
                  name: "workplace_row",
                  id: "workplace_row",
                  class: ["flex-container", "flex-horizontal"],
                  visibility: "contact_address_is_workplace",
                  children: [
                    {
                      type: "text",
                      name: "prf_workplace",
                      id: "prf_workplace",
                      label: "Χώρος εργασίας",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                  ],
                },
                {
                  component: "div",
                  name: "address_first_row",
                  id: "address_first_row",
                  class: [
                    "flex-container",
                    "flex-horizontal",
                    "flex-multiple-items",
                  ],
                  children: [
                    {
                      type: "text",
                      name: "prf_address_street",
                      id: "prf_address_street",
                      label: "Οδός",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                    {
                      type: "text",
                      name: "prf_address_number",
                      id: "prf_address_number",
                      label: "Αριθμός",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                    {
                      type: "text",
                      name: "prf_postal_code",
                      id: "prf_postal_code",
                      label: "ΤΚ",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                  ],
                },
                {
                  component: "div",
                  name: "address_second_row",
                  id: "address_second_row",
                  class: [
                    "flex-container",
                    "flex-horizontal",
                    "flex-multiple-items",
                  ],
                  children: [
                    {
                      type: "text",
                      name: "prf_address_city",
                      id: "prf_address_city",
                      label: "Πόλη",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                    {
                      type: "text",
                      name: "prf_address_country",
                      id: "prf_address_country",
                      label: "Χώρα",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          component: "div",
          name: "profession_container",
          id: "profession_container",
          class: ["logical-group"],
          children: [
            {
              component: "div",
              name: "profession_first_row",
              id: "profession_first_row",
              class: [
                "flex-container",
                "flex-horizontal",
                "flex-multiple-items",
              ],
              children: [
                {
                  type: "select",
                  name: "prf_profession_select",
                  id: "prf_profession_select",
                  label: "Επάγγελμα",
                  "label-class": "required-input-label",
                  "label-hasErrors-class": "input-hasError-label",
                  "outer-class": "input-outer",
                  "wrapper-class": "input-wrapper",
                  "error-class": "input-error-message",
                  "help-class": "input-help",
                  options: {
                    profession_doctor_choice: "Ιατρός",
                    profession_dentist_choice: "Οδοντίατρος",
                    profession_vet_choice: "Κτηνίατρος",
                    profession_pharmacist_choice: "Φαρμακοποιός",
                    profession_biologist_choice: "Βιολόγος",
                    profession_biochemist_choice: "Βιοχημικός",
                    profession_other_health_profession_choice:
                      "Άλλος Επαγγελματίας Υγείας",
                    profession_college_student_choice: "Φοιτητής",
                    profession_student_choice: "Σπουδαστής",
                    profession_other_choice: "Άλλο (Γράψτε)",
                  },
                  validation: ["^required"],
                },
                {
                  type: "text",
                  name: "prf_conditional_profession_other_input",
                  id: "prf_conditional_profession_other_input",
                  label: "Γράψτε το επάγγελμά σας",
                  "label-class": "required-input-label",
                  "label-hasErrors-class": "input-hasError-label",
                  "outer-class": "input-outer",
                  "wrapper-class": "input-wrapper",
                  "error-class": "input-error-message",
                  "help-class": "input-help",
                  visibility: "profession_is_other",
                  validation: ["^required"],
                },
              ],
            },
            {
              component: "div",
              name: "profession_college_student_container",
              id: "profession_college_student_container",
              visibility: "profession_choice_is_college_student",
              children: [
                {
                  component: "div",
                  name: "college_student_first_row",
                  id: "college_student_first_row",
                  class: ["flex-container", "flex-horizontal"],
                  children: [
                    {
                      type: "select",
                      name: "prf_college_student_select",
                      id: "prf_college_student_select",
                      label: "Φοιτητής",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      options: {
                        college_student_choice1: "Αισθητικής Κοσμετολογίας",
                        college_student_choice2:
                          "Ακτινολογίας & Αντινοθεραπείας",
                        college_student_choice3: "Βιολογίας",
                        college_student_choice4: "Βιοχημείας",
                        college_student_choice5: "Δημόσιας Υγείας",
                        college_student_choice6: "Διαιτολογίας - Διατροφής",
                        college_student_choice7: "Επισκέπτης Υγείας",
                        college_student_choice8: "Εργοθεραπείας",
                        college_student_choice9: "Ιατρικής",
                        college_student_choice10: "Ιατρικών Εργαστηρίων",
                        college_student_choice11: "Κοινωνικής Εργασίας",
                        college_student_choice12: "Κτηνιατρικής",
                        college_student_choice13: "Μαιευτικής",
                        college_student_choice14: "Νοσηλευτικής",
                        college_student_choice15: "Οδοντιατρικής",
                        college_student_choice16: "Οδοντικής Τεχνολογίας",
                        college_student_choice17: "Οπτικής & Οπτομετρίας",
                        college_student_choice18: "Προσχολικής αγωγής",
                        college_student_choice19: "Φαρμακολογίας",
                        college_student_choice20: "Φυσικοθεραπείας",
                        college_student_choice21: "Ψυχολογίας",
                        college_student_choice22: "Άλλο (Γράψτε)",
                      },
                      validation: ["^required"],
                    },
                  ],
                },
                {
                  component: "div",
                  name: "college_student_second_row",
                  id: "college_student_second_row",
                  visibility: "college_student_is_other",
                  class: ["flex-container", "flex-horizontal"],
                  children: [
                    {
                      type: "text",
                      name: "prf_conditional_college_student_other_input",
                      id: "prf_conditional_college_student_other_input",
                      label: "Γράψτε τον κλάδο σπουδών σας",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                  ],
                },
                {
                  component: "div",
                  name: "college_student_third_row",
                  id: "college_student_third_row",
                  class: [
                    "flex-container",
                    "flex-horizontal",
                    "flex-multiple-items",
                  ],
                  children: [
                    {
                      type: "text",
                      name: "prf_college_institution",
                      id: "prf_college_institution",
                      label: "Εκπαιδευτικό ίδρυμα",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                    {
                      type: "text",
                      name: "prf_college_department",
                      id: "prf_college_department",
                      label: "Σχολή",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                  ],
                },
                {
                  component: "div",
                  name: "college_student_fourth_row",
                  id: "college_student_fourth_row",
                  class: [
                    "flex-container",
                    "flex-horizontal",
                    "flex-multiple-items",
                  ],
                  children: [
                    {
                      type: "text",
                      name: "prf_college_department_class",
                      id: "prf_college_department_class",
                      label: "Τμήμα",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                    {
                      type: "text",
                      name: "prf_college_id_number",
                      id: "prf_college_id_number",
                      label: "Αριθμός φοιτητικής ταυτότητας",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                  ],
                },
              ],
            },
            {
              component: "div",
              name: "profession_student_container",
              id: "profession_student_container",
              visibility: "profession_choice_is_student",
              children: [
                {
                  component: "div",
                  name: "college_student_first_row",
                  id: "college_student_first_row",
                  class: [
                    "flex-container",
                    "flex-horizontal",
                    "flex-multiple-items",
                  ],
                  children: [
                    {
                      type: "text",
                      name: "prf_student_institution",
                      id: "prf_student_institution",
                      label: "Εκπαιδευτικό ίδρυμα",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                    {
                      type: "text",
                      name: "prf_student_department",
                      id: "prf_student_department",
                      label: "Σχολή",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                  ],
                },
                {
                  component: "div",
                  name: "student_second_row",
                  id: "student_second_row",
                  class: [
                    "flex-container",
                    "flex-horizontal",
                    "flex-multiple-items",
                  ],
                  children: [
                    {
                      type: "text",
                      name: "prf_student_department_class",
                      id: "prf_student_department_class",
                      label: "Τμήμα",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                  ],
                },
              ],
            },

            {
              component: "div",
              name: "profession_other_health_profession_container",
              id: "profession_other_health_profession_container",
              visibility: "profession_choice_is_other_health_profession",
              children: [
                {
                  component: "div",
                  name: "other_health_profession_first_row",
                  id: "other_health_profession_first_row",
                  class: [
                    "flex-container",
                    "flex-horizontal",
                    "flex-multiple-items",
                  ],
                  children: [
                    {
                      type: "select",
                      name: "prf_other_health_profession_select",
                      id: "prf_other_health_profession_select",
                      label: "Άλλος επαγγελματίας υγείας",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      options: {
                        other_health_profession_choice1:
                          "Αισθητικός - Κοσμετολόγος",
                        other_health_profession_choice2:
                          "Διαιτολόγος - Διατροφολόγος",
                        other_health_profession_choice3: "Επισκέπτης Υγείας",
                        other_health_profession_choice4:
                          "Επόπτης Δημόσιας Υγείας",
                        other_health_profession_choice5: "Εργοθεραπευτής",
                        other_health_profession_choice6: "Κοινωνική εργασία",
                        other_health_profession_choice7: "Λογοθεραπευτής",
                        other_health_profession_choice8: "Μαία - Μαιευτής",
                        other_health_profession_choice9:
                          "Νοσηλεύτρια - Νοσηλευτής",
                        other_health_profession_choice10: "Οδοντοτεχνίτης",
                        other_health_profession_choice11: "Οπτικός",
                        other_health_profession_choice12: "Προσχολική αγωγή",
                        other_health_profession_choice13:
                          "Τεχνολόγος Ιατρικών Εργαστηρίων",
                        other_health_profession_choice14:
                          "Τεχνολόγος Ραδιολογίας & Αντινολογίας",
                        other_health_profession_choice15:
                          "Φυσικοθεραπευτής - Φυσικοθεραπεύτρια",
                        other_health_profession_choice16: "Ψυχολόγος",
                        other_health_profession_choice17: "Άλλο (Γράψτε)",
                      },
                      validation: ["^required"],
                    },
                    {
                      type: "text",
                      name:
                        "prf_conditional_other_healt_profession_other_input",
                      id: "prf_conditional_other_healt_profession_other_input",
                      label: "Γράψτε το επάγγελμά σας",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      visibility: "other_health_profession_is_other",
                      validation: ["^required"],
                    },
                  ],
                },
              ],
            },
            {
              component: "div",
              name: "profession_second_row",
              id: "profession_second_row",
              visibility: "profession_choice_is_doctor",
              class: [
                "flex-container",
                "flex-horizontal",
                "flex-multiple-items",
              ],
              children: [
                {
                  type: "select",
                  name: "prf_specialty_select",
                  id: "prf_specialty_select",
                  label: "Ειδικότητα",
                  "label-class": "required-input-label",
                  "label-hasErrors-class": "input-hasError-label",
                  "outer-class": "input-outer",
                  "wrapper-class": "input-wrapper",
                  "error-class": "input-error-message",
                  "help-class": "input-help",
                  options: {
                    doctor_no_specialty_choice: "Άνευ ειδικότητας",
                    doctor_trainee: "Ειδικευόμενος",
                    doctor_specialized: "Ειδικευμένος",
                  },
                  validation: ["^required"],
                },
                {
                  type: "select",
                  name: "prf_doctor_specialty_select",
                  id: "prf_doctor_specialty_select",
                  label: "Ιατρική Ειδικότητα",
                  "label-class": "required-input-label",
                  "label-hasErrors-class": "input-hasError-label",
                  "outer-class": "input-outer",
                  "wrapper-class": "input-wrapper",
                  "error-class": "input-error-message",
                  "help-class": "input-help",
                  visibility: "doctor_is_trainee_or_specialized",
                  options: {
                    doctor_specialty_choice1: "Αγγειοχειρουργική",
                    doctor_specialty_choice2: "Αιματολογία",
                    doctor_specialty_choice3: "Ακτινοθεραπευτική Ογκολογία",
                    doctor_specialty_choice4: "Ακτινολογία",
                    doctor_specialty_choice5: "Αλλεργιολογία",
                    doctor_specialty_choice6: "Αναισθησιολογία",
                    doctor_specialty_choice7: "Γαστρεντρολογια",
                    doctor_specialty_choice8: "Γενική Ιατρική",
                    doctor_specialty_choice9: "Δερματολογία - Αφροδισιολογία",
                    doctor_specialty_choice10:
                      "Δημόσια Υγεία - Κοινωνική Ιατρική",
                    doctor_specialty_choice11: "Ενδοκρινολογία",
                    doctor_specialty_choice12: "Εσωτερική Παθολογία",
                    doctor_specialty_choice13: "Ιατρική Βιοπαθολογία",
                    doctor_specialty_choice14: "Ιατρική Γενετική",
                    doctor_specialty_choice15: "Ιατρική Εργασίας",
                    doctor_specialty_choice16: "Ιατροδικαστική",
                    doctor_specialty_choice17: "Καρδιολογία",
                    doctor_specialty_choice18: "Κυτταρολογία",
                    doctor_specialty_choice19: "Μαιευτική - Γυναικολογία",
                    doctor_specialty_choice20: "Νευρολογία",
                    doctor_specialty_choice21: "Νευροχειρουργική",
                    doctor_specialty_choice22: "Νεφρολογία",
                    doctor_specialty_choice23: "Ορθοπαιδική και Τραυματιολογία",
                    doctor_specialty_choice24: "Ουρολογία",
                    doctor_specialty_choice25: "Οφθαλμολογία",
                    doctor_specialty_choice26: "Παθολογική Ανατομική",
                    doctor_specialty_choice27: "Παθολογική Ογκολογία",
                    doctor_specialty_choice28: "Παιδιατρική",
                    doctor_specialty_choice29: "Πλαστική Χειρουργική",
                    doctor_specialty_choice30: "Πνευμονολογία - Φυματιολογία",
                    doctor_specialty_choice31: "Πυρηνική Ιατρική",
                    doctor_specialty_choice32: "Ρευματολογία",
                    doctor_specialty_choice33:
                      "Στοματική & Γναθοπροσωπική Χειρουργική",
                    doctor_specialty_choice34: "Φυσική Ιατρική & Απόκατάσταση",
                    doctor_specialty_choice35: "Χειρουργική",
                    doctor_specialty_choice36: "Χειρουργική Θώρακος",
                    doctor_specialty_choice37: "Χειρουργική Παίδων",
                    doctor_specialty_choice38: "Ψυχιατρική",
                    doctor_specialty_choice39: "Ψυχιατρική Παιδιού & Εφήβου",
                    doctor_specialty_choice40: "Ωτορινολαρυγγολογία",
                  },
                  validation: ["^required"],
                },
              ],
            },
            {
              component: "div",
              name: "profession_third_row",
              id: "profession_third_row",
              class: [
                "flex-container",
                "flex-horizontal",
                "flex-multiple-items",
              ],
              visibility: "profession_choice_is_doctor_and_specialized",
              children: [
                {
                  type: "radio",
                  name: "prf_ultra_specialty_radio",
                  id: "prf_ultra_specialty_radio",
                  label: "Εξειδικευμένος",
                  "label-class": "required-input-label",
                  "label-hasErrors-class": "input-hasError-label",
                  "outer-class": "input-outer",
                  "wrapper-class": "input-wrapper",
                  "error-class": "input-error-message",
                  "help-class": "input-help",
                  class: ["radio-container"],
                  options: {
                    doctor_ultra_specialized: "Ναι",
                    doctor_not_ultra_specialized: "Όχι",
                  },
                  validation: ["^required"],
                },
                {
                  type: "select",
                  name: "prf_doctor_ultra_specialty_select",
                  id: "prf_doctor_ultra_specialty_select",
                  label: "Ιατρική Εξειδίκευση",
                  "label-class": "required-input-label",
                  "label-hasErrors-class": "input-hasError-label",
                  "outer-class": "input-outer",
                  "wrapper-class": "input-wrapper",
                  "error-class": "input-error-message",
                  "help-class": "input-help",
                  visibility: "doctor_is_ultra_specialized",
                  options: {
                    doctor_ultra_specialty_choice1: "Επείγουσα Ιατρική",
                    doctor_ultra_specialty_choice2: "Επεμβατική ακτινολογία",
                    doctor_ultra_specialty_choice3: "Κλινική Μικροβιολογία",
                    doctor_ultra_specialty_choice4: "Λοιμωξιολογία",
                    doctor_ultra_specialty_choice5: "Εντατική θεραπεία",
                    doctor_ultra_specialty_choice6: "Εντατική νοσηλεία νεογνών",
                    doctor_ultra_specialty_choice7: "Σακχαρώδης διαβήτης",
                  },
                  validation: ["^required"],
                },
              ],
            },
            {
              component: "div",
              name: "profession_fifth_row",
              id: "profession_fifth_row",
              visibility: "profession_choice_is_doctor",
              class: [
                "flex-container",
                "flex-horizontal",
                "flex-multiple-items",
              ],
              children: [
                {
                  type: "text",
                  name: "prf_doctor_team_input",
                  id: "prf_doctor_team_input",
                  label: "Ιατρικός σύλλογος",
                  "label-hasErrors-class": "input-hasError-label",
                  "outer-class": "input-outer",
                  "wrapper-class": "input-wrapper",
                  "error-class": "input-error-message",
                  "help-class": "input-help",
                  validation: [],
                },
                {
                  type: "text",
                  name: "prf_registration_number",
                  id: "prf_registration_number",
                  label: "Αριθμός μητρώου",
                  "label-hasErrors-class": "input-hasError-label",
                  "outer-class": "input-outer",
                  "wrapper-class": "input-wrapper",
                  "error-class": "input-error-message",
                  "help-class": "input-help",
                  validation: [],
                },
              ],
            },
            {
              component: "div",
              name: "profession_sixth_row",
              id: "profession_sixth_row",
              visibility: "profession_choice_is_doctor",
              class: ["flex-container", "flex-horizontal"],
              children: [
                {
                  type: "text",
                  name: "prf_scientific_company_member",
                  id: "prf_scientific_company_member",
                  label: "Μέλος επιστημονικής εταιρείας",
                  "label-hasErrors-class": "input-hasError-label",
                  "outer-class": "input-outer",
                  "wrapper-class": "input-wrapper",
                  "error-class": "input-error-message",
                  "help-class": "input-help",
                  validation: [],
                },
              ],
            },
          ],
        },
        {
          component: "div",
          name: "invoice_container",
          id: "invoice_container",
          class: ["logical-group"],
          children: [
            {
              component: "div",
              name: "invoice_radio_row",
              id: "invoice_radio_row",
              class: ["flex-container", "flex-horizontal"],
              children: [
                {
                  type: "radio",
                  name: "prf_invoice_radio",
                  id: "prf_invoice_radio",
                  label: "Θέλετε έκδοση τιμολογίου;",
                  "label-class": "required-input-label",
                  "label-hasErrors-class": "input-hasError-label",
                  "outer-class": "input-outer",
                  "wrapper-class": "input-wrapper",
                  "error-class": "input-error-message",
                  "help-class": "input-help",
                  class: ["radio-container"],
                  options: {
                    invoice_yes_choice: "Ναι",
                    invoice_no_choice: "Οχι",
                  },
                  validation: ["^required"],
                },
              ],
            },

            {
              component: "table",
              name: "conditional_invoice_container",
              id: "conditional_invoice_container",
              visibility: "invoice_choice_is_yes",
              children: [
                {
                  component: "tr",
                  name: "invoice_brandname_container",
                  id: "invoice_brandname_container",
                  class: [],
                  children: [
                    {
                      component: "td",
                      id: "invoice_brandname_cell",
                      name: "invoice_brandname_cell",
                      children: [
                        {
                          type: "text",
                          name: "prf_invoice_brandname",
                          id: "prf_invoice_brandname",
                          label: "Επωνυμία",
                          "label-class": "required-input-label",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          autocomplete: "invoice_brandname_checkbox_is_same",
                          validation: ["^required"],
                        },
                      ],
                    },
                    {
                      component: "td",
                      id: "invoice_brandname_checkbox_cell",
                      name: "invoice_brandname_checkbox_cell",
                      children: [
                        {
                          type: "checkbox",
                          name: "prf_invoice_brandname_checkbox",
                          id: "prf_invoice_brandname_checkbox",
                          label: "Ίδιο με τα ανωτέρω",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          class: ["checkbox-container"],
                        },
                      ],
                    },
                  ],
                },
                {
                  component: "tr",
                  name: "invoice_profession_container",
                  id: "invoice_profession_container",
                  class: [],
                  children: [
                    {
                      component: "td",
                      id: "invoice_profession_select_cell",
                      name: "invoice_profession_select_cell",
                      children: [
                        {
                          type: "text",
                          name: "prf_invoice_profession_select",
                          id: "prf_invoice_profession_select",
                          label: "Επάγγελμα",
                          "label-class": "required-input-label",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          autocomplete: "invoice_profession_checkbox_is_same",
                          validation: ["^required"],
                        },
                      ],
                    },
                    {
                      component: "td",
                      id: "invoice_profession_checkbox_cell",
                      name: "invoice_profession_checkbox_cell",
                      children: [
                        {
                          type: "checkbox",
                          name: "prf_invoice_profession_checkbox",
                          id: "prf_invoice_profession_checkbox",
                          label: "Ίδιο με τα ανωτέρω",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          class: ["checkbox-container"],
                        },
                      ],
                    },
                  ],
                },
                {
                  component: "tr",
                  name: "invoice_address_street_container",
                  id: "invoice_address_street_container",
                  class: [],
                  children: [
                    {
                      component: "td",
                      id: "invoice_address_street_cell",
                      name: "invoice_address_street_cell",
                      children: [
                        {
                          type: "text",
                          name: "prf_invoice_address_street",
                          id: "prf_invoice_address_street",
                          label: "Οδός",
                          "label-class": "required-input-label",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          autocomplete:
                            "invoice_address_street_checkbox_is_same",
                          validation: ["^required"],
                        },
                      ],
                    },
                    {
                      component: "td",
                      id: "invoice_address_street_checkbox_cell",
                      name: "invoice_address_street_checkbox_cell",
                      children: [
                        {
                          type: "checkbox",
                          name: "prf_invoice_address_street_checkbox",
                          id: "prf_invoice_address_street_checkbox",
                          label: "Ίδιο με τα ανωτέρω",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          class: ["checkbox-container"],
                        },
                      ],
                    },
                  ],
                },
                {
                  component: "tr",
                  name: "invoice_address_number_container",
                  id: "invoice_address_number_container",
                  class: [],
                  children: [
                    {
                      component: "td",
                      id: "invoice_address_number_cell",
                      name: "invoice_address_number_cell",
                      children: [
                        {
                          type: "text",
                          name: "prf_invoice_address_number",
                          id: "prf_invoice_address_number",
                          label: "Αριθμός",
                          "label-class": "required-input-label",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          autocomplete:
                            "invoice_address_number_checkbox_is_same",
                          validation: ["^required"],
                        },
                      ],
                    },
                    {
                      component: "td",
                      id: "invoice_address_number_checkbox_cell",
                      name: "invoice_address_number_checkbox_cell",
                      children: [
                        {
                          type: "checkbox",
                          name: "prf_invoice_address_number_checkbox",
                          id: "prf_invoice_address_number_checkbox",
                          label: "Ίδιο με τα ανωτέρω",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          class: ["checkbox-container"],
                        },
                      ],
                    },
                  ],
                },
                {
                  component: "tr",
                  name: "invoice_address_city_container",
                  id: "invoice_address_city_container",
                  class: [],
                  children: [
                    {
                      component: "td",
                      id: "invoice_address_city_cell",
                      name: "invoice_address_city_cell",
                      children: [
                        {
                          type: "text",
                          name: "prf_invoice_address_city",
                          id: "prf_invoice_address_city",
                          label: "Πόλη",
                          "label-class": "required-input-label",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          autocomplete: "invoice_address_city_checkbox_is_same",
                          validation: ["^required"],
                        },
                      ],
                    },
                    {
                      component: "td",
                      id: "invoice_address_city_checkbox_cell",
                      name: "invoice_address_city_checkbox_cell",
                      children: [
                        {
                          type: "checkbox",
                          name: "prf_invoice_address_city_checkbox",
                          id: "prf_invoice_address_city_checkbox",
                          label: "Ίδιο με τα ανωτέρω",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          class: ["checkbox-container"],
                        },
                      ],
                    },
                  ],
                },
                {
                  component: "tr",
                  name: "invoice_postal_code_container",
                  id: "invoice_postal_code_container",
                  class: [],
                  children: [
                    {
                      component: "td",
                      id: "invoice_postal_code_cell",
                      name: "invoice_postal_code_cell",
                      children: [
                        {
                          type: "text",
                          name: "prf_invoice_postal_code",
                          id: "prf_invoice_postal_code",
                          label: "ΤΚ",
                          "label-class": "required-input-label",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          autocomplete: "invoice_postal_code_checkbox_is_same",
                          validation: ["^required"],
                        },
                      ],
                    },
                    {
                      component: "td",
                      id: "invoice_postal_code_checkbox_cell",
                      name: "invoice_postal_code_checkbox_cell",
                      children: [
                        {
                          type: "checkbox",
                          name: "prf_invoice_postal_code_checkbox",
                          id: "prf_invoice_postal_code_checkbox",
                          label: "Ίδιο με τα ανωτέρω",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          class: ["checkbox-container"],
                        },
                      ],
                    },
                  ],
                },
                {
                  component: "tr",
                  name: "invoice_country_container",
                  id: "invoice_country_container",
                  class: [],
                  children: [
                    {
                      component: "td",
                      id: "invoice_country_cell",
                      name: "invoice_country_cell",
                      children: [
                        {
                          type: "text",
                          name: "prf_invoice_country",
                          id: "prf_invoice_country",
                          label: "Χώρα",
                          "label-class": "required-input-label",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          autocomplete: "invoice_country_checkbox_is_same",
                          validation: ["^required"],
                        },
                      ],
                    },
                    {
                      component: "td",
                      id: "invoice_country_checkbox_cell",
                      name: "invoice_country_checkbox_cell",
                      children: [
                        {
                          type: "checkbox",
                          name: "prf_invoice_country_checkbox",
                          id: "prf_invoice_country_checkbox",
                          label: "Ίδιο με τα ανωτέρω",
                          "label-hasErrors-class": "input-hasError-label",
                          "outer-class": "input-outer",
                          "wrapper-class": "input-wrapper",
                          "error-class": "input-error-message",
                          "help-class": "input-help",
                          class: ["checkbox-container"],
                        },
                      ],
                    },
                  ],
                },
                {
                  component: "div",
                  name: "invoice_vat_number_row",
                  id: "invoice_vat_number_row",
                  class: ["flex-container", "flex-horizontal"],
                  children: [
                    {
                      type: "text",
                      name: "prf_invoice_vat_number",
                      id: "prf_invoice_vat_number",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      label: "Α.Φ.Μ.",
                      validation: ["^required"],
                    },
                  ],
                },
                {
                  component: "div",
                  name: "invoice_tax_office_row",
                  id: "invoice_tax_office_row",
                  class: ["flex-container", "flex-horizontal"],
                  children: [
                    {
                      type: "text",
                      name: "prf_invoice_tax_office",
                      id: "prf_invoice_tax_office",
                      label: "Δ.Ο.Υ.",
                      "label-class": "required-input-label",
                      "label-hasErrors-class": "input-hasError-label",
                      "outer-class": "input-outer",
                      "wrapper-class": "input-wrapper",
                      "error-class": "input-error-message",
                      "help-class": "input-help",
                      validation: ["^required"],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],

  [
    {
      component: "div",
      name: "step_3_content",
      id: "step_3_content",
      class: ["flex-container flex-row-center--h"],
      children: [
        {
          component: "div",
          name: "step_3_content_div",
          id: "step_3_content_div",
          children: [
            {
              component: "h5",
              name: "choose_registration_plan_header",
              id: "choose_registration_plan_header",
              children: "Επιλέξτε εγγραφή",
            },
            {
              type: "radio",
              name: "subscription_method_radio",
              id: "subscription_method_radio",
              "label-hasErrors-class": "input-hasError-label",
              "outer-class": "input-outer",
              "wrapper-class": "input-wrapper",
              "error-class": "input-error-message",
              "help-class": "input-help",
              // "value": "standard",
              options: {
                standard: "Εγγραφή - ΧΧΧ €",
              },
              validation: ["^required"],
            },
            {
              component: "h5",
              name: "choose_payment_method_header",
              id: "choose_payment_method_header",
              children: "Επιλέξτε έναν τρόπο πληρωμής",
            },
            {
              type: "radio",
              name: "payment_method_radio",
              id: "payment_method_radio",
              "label-hasErrors-class": "input-hasError-label",
              "outer-class": "input-outer",
              "wrapper-class": "input-wrapper",
              "error-class": "input-error-message",
              "help-class": "input-help",
              // "value": "bank",
              options: {
                bank: "Κατάθεση",
              },
              validation: ["^required"],
            },
          ],
        },
      ],
    },
  ],
  [],
  [],
];

const chargeStep = {
  subscription_header: "Επιλέξτε εγγραφή",
  subscriptions: [
    {
      title: "Εγγραφή για διαδικτυακή παρακολούθηση",
      price: "62",
      currency: "€",
      asterisk: "*",
      code: "sub_normal_plan",
      eligibility: "not_student",
      description: "",
    },
    {
      title: "Εγγραφή Φοιτητών για Διαδικτυακή παρακολούθηση – Δωρεάν",
      price: "0",
      currency: "€",
      asterisk: "",
      code: "sub_student_plan",
      eligibility: "student",
      description:
        "Για την επιβεβαίωση της εγγραφής σας, απαιτείται η αποστολή βεβαίωσης της ιδιότητάς σας (πάσο ή άλλο έγγραφο από το ίδρυμα φοίτησής σας που να πιστοποιεί την ιδιότητά σας) στο events@focusonhealth.gr",
    },
  ],
  payment_header: "Επιλέξτε έναν τρόπο πληρωμής",
  payment_methods: [
    {
      title: "Κατάθεση",
      price: "62",
      currency: "€",
      asterisk: "*",
      code: "pay_statement",
      eligibility: "not_student",
      description: "",
    },
  ],
  step_footer: "*Συμπεριλαμβάνεται 24% ΦΠΑ.",
};

export { chargeStep };
export { schema };
