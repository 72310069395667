import * as jsonLogic from "../../../node_modules/json-logic-js/logic";

// DFS the schema, when an input has a visibility expression -> false
// delete it from its parent array
export function dfs(schema, expressions, values) {
  var stack = [];
  var visited = {};

  // Copy the array and the JSON objects inside

  var result_schema = JSON.parse(JSON.stringify(schema));

  stack.push({ element: result_schema[0], set: result_schema });

  while (stack.length) {
    var s = stack.pop();

    if (!(s["element"]["id"] in visited)) {
      visited[s["element"]["id"]] = true;
    }

    var continue_ = true;

    // Check for visibility
    if (s["element"] == "ς") {
      console.log(s["set"]);
    }
    if ("visibility" in s["element"]) {
      continue_ = jsonLogic.apply(
        expressions["visibility"][s["element"]["visibility"]],
        values
      );
    }

    var parentArray = s["set"];

    if (!continue_) {
      // Remove the field and all of its children from its set
      // the set could be schema, or the children array of another component

      for (var i = 0; i < parentArray.length; i++) {
        if (parentArray[i] === s["element"]) {
          parentArray.splice(i, 1);
        }
      }
    } else {
      // If group, check if validation is required
      if ("type" in s["element"] && s["element"]["type"] === "group") {
        const validation_classes = s["element"]["validation_class"];
        const validation_args = s["element"]["validation_args"];

        for (const validation_class of validation_classes) {
          switch (validation_class) {
            case "minimum-fields-requirement":
              var reqNumberOfFields = parseInt(
                validation_args[validation_class]
              );

              var count = parseInt(reqNumberOfFields);

              // check if there are enough fields typed to exceed the minimum requirement
              if (count > 0) {
                if (values[s["element"]["name"]] != undefined) {
                  // Save the group array into a new object cause it gets emptied when moving steps
                  var keyString = s["element"]["name"];
                  values[keyString + "_saved"] = JSON.parse(
                    JSON.stringify(values[s["element"]["name"]][0])
                  );

                  const group_items_values = values[s["element"]["name"]][0];

                  const numberOfItems = Object.keys(group_items_values).length;

                  count -= numberOfItems;
                }
              }

              // If minimum requirement fails, add a validation rule to the group, that returns false
              if (count > 0) {
                s["element"]["validation"].push(
                  `contactPhonesRule:${reqNumberOfFields}`
                );
              }

              break;

            case "step-group-validation":
              break;

            default:
              break;
          }
        }
      }

      if ("validate_passwords" in s["element"]) {
        var passwordsMatch = jsonLogic.apply(
          expressions["validate_passwords"][s["element"]["validate_passwords"]],
          values
        );

        if (!passwordsMatch) {
          s["element"]["validation"].push("matchingPasswordsRule");
        }
      }

      // If autocomplete, set it up
      if ("autocomplete" in s["element"]) {
        const autocompleteKey = s["element"]["autocomplete"];

        var autocompleteExpression =
          expressions["autocomplete"][autocompleteKey]["expression"];

        var autocompleteValue =
          expressions["autocomplete"][autocompleteKey]["autocomplete_value"];

        var autocomplete_ = jsonLogic.apply(autocompleteExpression, values);

        if (autocomplete_) {
          const professionSelection =
            expressions["autocomplete"][autocompleteKey]["selection"];
          if (Object.keys(professionSelection).length) {
            // Not Text (select, radio, checkbox..)
            const professionChoiceName = values[autocompleteValue];
            var professionLabel =
              professionChoiceName == "profession_other_choice"
                ? values[professionSelection[professionChoiceName]]
                : professionSelection[professionChoiceName];

            values[s["element"]["name"]] = professionLabel;
          } else {
            // In case of text input
            // s["element"]["value"] = values[autocompleteValue]
            values[s["element"]["name"]] = values[autocompleteValue];
          }
        }
      }

      // (1) First, push the next input from s
      // (2) Then, push the children inputs of s
      // That way, the algo will continue with the next input from s,
      // only after it's done with s's children

      // (1) Push the next input only if it exists
      var indx = s["set"].indexOf(s["element"]);
      if (indx < s["set"].length - 1) {
        stack.push({ element: s["set"][indx + 1], set: parentArray });
      }

      // (2) Push s's children if they exist
      if (
        "children" in s["element"] &&
        Array.isArray(s["element"]["children"])
      ) {
        for (const child of s["element"]["children"]) {
          if (!(child["id"] in visited)) {
            parentArray = s["element"]["children"];
            stack.push({ element: child, set: parentArray });
          }
        }
      }
    }
  }

  return result_schema;
}
